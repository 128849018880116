import styled from 'styled-components';

const Wrap = styled.div`
  margin-top: 24px;
`;

const ActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0 24px auto;
`;

export { Wrap, ActionWrap };
