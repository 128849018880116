import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { prepareFormForSend } from 'utils/prepare-fields';
import { handleServerErrors } from 'utils/server-errors';

const signUpValidation = createAsyncThunk(
  'registration/signUpValidation',
  async ({ step, ...fields }) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.registration.signUpValidation,
        {
          step,
          ...prepareFormForSend(fields),
        }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const profileInfo = createAsyncThunk(
  'registration/profileInfo',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.registration.profileInfo,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const companyInfo = createAsyncThunk(
  'registration/companyInfo',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.registration.companyInfo,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendVerifyCode = createAsyncThunk(
  'registration/sendVerifyCode',
  async ({ token, to, email, phone }) => {
    try {
      const body = {
        to,
        ...(email && { email }),
        ...(phone && { phone }),
      };

      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await api.post(
        WEB_API_ROUTES.registration.sendVerifyCode,
        body,
        {
          headers,
        }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const verifyPhone = createAsyncThunk(
  'registration/verifyPhone',
  async ({ code, phone }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.registration.verifyPhone, {
        code,
        phone,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const verifyEmail = createAsyncThunk(
  'registration/verifyEmail',
  async ({ code, email }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.registration.verifyEmail, {
        code,
        email,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const completeRegistration = createAsyncThunk(
  'registration/completeRegistration',
  async () => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.registration.completeRegistration
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  signUpValidation,
  sendVerifyCode,
  profileInfo,
  companyInfo,
  verifyEmail,
  verifyPhone,
  completeRegistration,
};

export { thunks };
