const selectors = {
  profile: (state) => state.general.profile,
  isSidebarOpen: (state) => state.general.isSidebarOpen,

  recipientsList: (state) => state.general.recipientsList,
  search: (state) => state.general.search,
  teachers: (state) => state.general.teachers,
  states: (state) => state.general.states,
  cities: (state) => state.general.cities,
  countries: (state) => state.general.countries,
  locations: (state) => state.general.locations,
  fetchingCitiesStatus: (state) => state.general.fetchingCitiesStatus,
  fetchingStatesStatus: (state) => state.general.fetchingStatesStatus,
  fetchingSearchStatus: (state) => state.general.fetchingSearchStatus,
};

export { selectors };
