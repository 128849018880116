import RcPagination from 'rc-pagination';
import PropTypes from 'prop-types';

import { PAGINATION_ARROW_SVG } from 'components/ui/_shared/SVG';
import { Wrap } from './styled';

export const RPagination = ({
  css,
  current = 1,
  total = 1,
  perPage = 15,
  onChange = () => {},
}) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderPages = ({ current, type, element }) => {
    return <>{type === 'page' ? current : element}</>;
  };

  const itemRender = (current, type, element) => (
    <RenderPages
      current={current}
      type={type}
      element={element}
    />
  );

  return (
    <Wrap css={css}>
      <RcPagination
        current={current}
        total={total}
        pageSize={perPage}
        itemRender={itemRender}
        prevIcon={<PAGINATION_ARROW_SVG />}
        nextIcon={<PAGINATION_ARROW_SVG isRight />}
        jumpPrevIcon="..."
        jumpNextIcon="..."
        onChange={onChange}
      />
    </Wrap>
  );
};

RPagination.propTypes = {
  css: PropTypes.any,
  current: PropTypes.number,
  total: PropTypes.number,
  perPage: PropTypes.number,
  onChange: PropTypes.func,
};
