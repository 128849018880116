import yup from 'plugins/yup-config';

const defaultValues = {
  subject: '',
  text: '',
  type: '',
  recipientSearch: { value: '', label: '' },
  recipients: [],
};

const schema = yup.object().shape({
  type: yup.string(),
  subject: yup.string().when('type', {
    is: 'sms',
    then: yup.string(),
    otherwise: yup.string().required('Subject is required'),
  }),
  messageText: yup.string().required('Message text is required'),
  recipients: yup
    .array()
    .min(1, 'At least one recipient is required')
    .required('Field is required'),
});

export { defaultValues, schema };
