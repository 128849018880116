import styled from 'styled-components';

const PopupButtonStyled = styled.button`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin: 0;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background-color: ${({ theme }) => theme.gray25};
  cursor: pointer;

  color: ${({ theme }) => theme.brand400};
  font-size: 16px;
  font-weight: 500;

  transition: all 0.3s;

  &:hover {
    background-color: #f4f3f9;
    border: 1px solid ${({ theme }) => theme.brand150};
  }
`;

const ChildrenWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export { ChildrenWrapperStyled, PopupButtonStyled };
