import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { RModalPortal } from 'components/RModalPortal';
import { RPopup } from 'components/RPopup';
import { RButtonIcon } from 'components/ui/RButtonIcon';
import { CROSS_SVG } from 'components/ui/_shared/SVG';
import { RAvatar } from 'components/RAvatar';
import { CheckboxField } from 'components/ui/RCheckboxField';
import { RButton } from 'components/ui/RButton';
import { useToggle } from 'hooks/useToggle';
import { RSearchField } from 'components/ui/RSearchField';
import {
  BodyStyled,
  FooterStyled,
  HeaderStyled,
  ItemsWrapStyled,
  MultipleSelectModalStyled,
  OptionLabelStyled,
  OptionStyled,
} from './styled';

export const MultipleSelectModal = ({
  name,
  placeholder,
  options,
  selectedOptions = [],
  onSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState([...selectedOptions]);
  const [loading, toggleLoading] = useToggle(false);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    setValue([...selectedOptions]);
  }, [selectedOptions]);

  const filteredOptions = useMemo(
    () =>
      options?.filter(
        (option) =>
          filterValue.length === 0 ||
          option.label.toLowerCase().includes(filterValue.toLowerCase())
      ),
    [filterValue, options]
  );

  const onChangeFilterValue = (val) => setFilterValue(val || '');

  const handleItemClick = (option) => () => {
    const alreadySelected = value.some((o) => o.value === option.value);

    if (alreadySelected) {
      setValue(value.filter((o) => o.value !== option.value));
      return;
    }

    setValue([...value, option]);
  };

  const handleSubmit = async () => {
    toggleLoading();

    try {
      await onSubmit(value);

      onClose();
    } catch (e) {
      console.error(e);
    }

    toggleLoading();
  };

  return (
    <RModalPortal>
      <RPopup>
        <MultipleSelectModalStyled>
          <HeaderStyled>
            {name}
            <RButtonIcon onClick={onClose}>
              <CROSS_SVG
                size={24}
                color={theme.gray200}
              />
            </RButtonIcon>
          </HeaderStyled>

          <BodyStyled>
            <RSearchField
              fullWidth
              placeholder={placeholder}
              value={filterValue}
              onChange={onChangeFilterValue}
              amountSymbols={1}
              disableDebounce
            />

            <ItemsWrapStyled>
              {filteredOptions?.map((option) => (
                <OptionStyled
                  key={option.value}
                  onClick={handleItemClick(option)}
                >
                  <OptionLabelStyled>
                    {option.path && (
                      <RAvatar
                        size={32}
                        thumb={option.path}
                      />
                    )}

                    {option.label}
                  </OptionLabelStyled>

                  <CheckboxField
                    checked={value.some((i) => i.value === option.value)}
                    disabled
                  />
                </OptionStyled>
              ))}
            </ItemsWrapStyled>

            <FooterStyled>
              <RButton
                loading={loading}
                onClick={handleSubmit}
                // disabled={value.length === 0}
              >
                Confirm
              </RButton>
            </FooterStyled>
          </BodyStyled>
        </MultipleSelectModalStyled>
      </RPopup>
    </RModalPortal>
  );
};

MultipleSelectModal.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
