import PropTypes from 'prop-types';

import { ReactComponent as AvatarEmpty } from 'assets/img/global/avatar-empty.svg';
import {
  Main,
  Wrap,
  Image,
  Name,
  DeleteAvatar,
} from 'components/RAvatar/styled';
import { REMOVE_SVG } from 'components/ui/_shared/SVG';

RAvatar.propTypes = {
  size: PropTypes.number,
  thumb: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onRemoveAvatar: PropTypes.func,
};

export function RAvatar({
  size = 50,
  thumb,
  name,
  onClick = () => {},
  onRemoveAvatar,
}) {
  // TODO: remove name displaying?
  let formattedName;

  if (!thumb && name) {
    const splitName = name.split(' ');
    formattedName =
      splitName.length > 1
        ? splitName[0].charAt(0) + splitName[1].charAt(0)
        : splitName[0].substr(0, 1);
  }

  const renderAvatar = () => {
    if (thumb) {
      return <Image src={thumb} />;
    }

    if (name && !thumb) {
      return <Name size={size}>{formattedName}</Name>;
    }

    return <AvatarEmpty />;
  };

  return (
    <Main onClick={onClick}>
      <Wrap size={size}>
        {renderAvatar()}
        {!!onRemoveAvatar && thumb && (
          <DeleteAvatar onClick={onRemoveAvatar}>
            <REMOVE_SVG />
          </DeleteAvatar>
        )}
      </Wrap>
    </Main>
  );
}
