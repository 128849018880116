import styled from 'styled-components';

const CardHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray950};

  ${({ css }) => css}
`;

const Title = styled.strong`
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
`;

const TooltipStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const EditButton = styled.div`
  display: flex;
  cursor: pointer;
`;

export { CardHeaderStyled, Title, TooltipStyled, EditButton };
