import styled, { css } from 'styled-components';

import { RootFieldInput, RootFieldIcons } from '../_shared/styled';

const FieldInput = styled(RootFieldInput)`
  padding: 16px 40px 16px 16px;
`;

const FieldIcons = styled(RootFieldIcons)`
  right: 8px;
  transform: ${({ isOpened }) =>
    isOpened
      ? 'translateY(-50%) rotate(-180deg)'
      : 'translateY(-50%) rotate(0deg)'};
  transition: transform 0.3s ease-in-out;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      svg path {
        stroke: ${theme.gray200};
      }
    `};
`;

export { FieldInput, FieldIcons };
