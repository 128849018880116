const guardians = (state) => {
  const pending = state.dancerDetails.relationships.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.dancerDetails.relationships.filter(
    (g) => g.status === 'saved'
  );

  return { pending, saved };
};

const selectors = {
  guardians,
  dancerInfo: (state) => state.dancerDetails.dancerInfo,
  relationships: (state) => state.dancerDetails.relationships,
  notifications: (state) => state.dancerDetails.notifications,
  createGuardianStatus: (state) => state.dancerDetails.createGuardianStatus,
  dancerEvaluation: (state) => state.dancerDetails.dancerEvaluation,
  dancerUpcomingAppointments: (state) =>
    state.dancerDetails.dancerUpcomingAppointments,
  loadingAppointmentsStatus: (state) =>
    state.dancerDetails.loadingAppointmentsStatus,

  ratings: (state) => state.dancerDetails.ratings,
  dancerClasses: (state) => state.dancerDetails.dancerClasses,
  availableClasses: (state) => state.dancerDetails.availableClasses,

  routines: {
    dancerRoutines: (state) => state.dancerDetails.routines.dancerRoutines,
    loadingDancerRoutinesStatus: (state) =>
      state.dancerDetails.routines.loadingDancerRoutinesStatus,
    list: (state) => state.dancerDetails.routines.list,
    currentPage: (state) => state.dancerDetails.routines.currentPage,
  },

  performances: {
    dancerPerformances: (state) =>
      state.dancerDetails.performances.dancerPerformances,
    currentPage: (state) => state.dancerDetails.performances.currentPage,
    loadingPerformanceStatus: (state) =>
      state.dancerDetails.performances.loadingPerformanceStatus,
  },

  loadingNotificationsStatus: (state) =>
    state.dancerDetails.loadingNotificationsStatus,
  loadingDancerInfoStatus: (state) =>
    state.dancerDetails.loadingDancerInfoStatus,
  getEvaluationStatus: (state) => state.dancerDetails.getEvaluationStatus,
  loadingClassesStatus: (state) => state.dancerDetails.loadingClassesStatus,
  loadingAvailableClassesStatus: (state) =>
    state.dancerDetails.loadingAvailableClassesStatus,
  loadingRatingStatus: (state) => state.dancerDetails.loadingRatingStatus,
};

export { selectors };
