import styled, { css } from 'styled-components';

import TickIcon from 'assets/img/ui/tick.svg';

const Wrap = styled.div``;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  column-gap: 8px;
  cursor: pointer;
  user-select: none;
  line-height: 1rem;
  color: ${({ theme }) => theme.gray400};
  font-weight: 500;

  &::before {
    content: '';
    display: inline-block;
    margin: 4px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.gray200};
    transition: background-color 0.3s;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: auto;
      color: ${({ theme }) => theme.gray100};

      &::before {
        border: 1px solid ${({ theme }) => theme.gray100};
      }
    `}
`;

const Input = styled.input`
  display: none;

  &:checked + ${Label}::before {
    background: ${({ theme }) => theme.brand500} url(${TickIcon}) no-repeat
      center;
  }
`;

export { Wrap, Label, Input };
