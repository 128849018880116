const selectors = {
  list: (state) => state.parents.list,
  loadingListStatus: (state) => state.parents.loadingListStatus,

  filters: (state) => state.parents.filters,
  preparedEntities: (state) => state.parents.preparedEntities,
  pagination: (state) => state.parents.pagination,
};

export { selectors };
