import styled from 'styled-components';

const MessageFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 500px;
  width: 400px;
  overflow-y: auto;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.gray25};
  box-shadow: 0px 6px 14px 0px #180c3224;
`;

const CloseBtnStyled = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

const FieldWrapperStyled = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: ${({ removePadding }) => (removePadding ? '0' : '18px')};
  border-bottom: 1px solid ${({ theme }) => theme.gray75};

  ${({ css }) => css};
`;

const AddRecipientStyled = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-left: auto;
  cursor: pointer;
`;

const AddRecipientInputStyled = styled.div`
  position: absolute;
  bottom: -60px;
  background-color: ${({ theme }) => theme.gray25};
  z-index: 1;
  border-radius: 8px;
`;

const ErrorStyled = styled.div`
  color: ${({ theme }) => theme.red500};
  font-size: 12px;
`;

const BtnsWrapperStyled = styled.div`
  display: flex;
  margin-left: auto;
  gap: 12px;
`;

export {
  MessageFormStyled,
  CloseBtnStyled,
  FieldWrapperStyled,
  BtnsWrapperStyled,
  AddRecipientInputStyled,
  AddRecipientStyled,
  ErrorStyled,
};
