const relatives = (state) => {
  const pending = state.parentDetails.editDancers.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.parentDetails.editDancers.filter(
    (g) => g.status === 'saved'
  );

  return { pending, saved };
};

const selectors = {
  editDancers: relatives,
  parentInfo: (state) => state.parentDetails.parentInfo,
  relationships: (state) => state.parentDetails.relationships,
  notifications: (state) => state.parentDetails.notifications,
  dancers: (state) => state.parentDetails.dancers,
  parentClasses: (state) => state.parentDetails.parentClasses,
  loadingClassesStatus: (state) => state.parentDetails.loadingClassesStatus,
  loadingParentInfoStatus: (state) =>
    state.parentDetails.loadingParentInfoStatus,
  createDancerStatus: (state) => state.parentDetails.createDancerStatus,
  loadingNotificationsStatus: (state) =>
    state.parentDetails.loadingNotificationsStatus,
  loadingDancersStatus: (state) => state.parentDetails.loadingDancersStatus,
};

export { selectors };
