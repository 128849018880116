import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: ${({ p }) => p};
  margin: ${({ m }) => m};
  background-color: ${({ theme }) => theme.gray25};

  ${({ css }) => css}
`;

export const Card = ({
  m = 0,
  p = '24px',
  css,
  className,
  onClick,
  children,
}) => (
  <CardStyled
    m={m}
    p={p}
    css={css}
    className={className}
    onClick={onClick}
  >
    {children}
  </CardStyled>
);

Card.propTypes = {
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  css: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
