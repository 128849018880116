import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  profile: null,
  isSidebarOpen: false,
  recipientsList: [],
  teachers: [],
  locations: [],
  states: [],
  cities: [],
  countries: [],
  search: null,
  fetchingStatesStatus: status.IDLE,
  fetchingCitiesStatus: status.IDLE,
  fetchingSearchStatus: status.IDLE,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSidebarOpen: (state, { payload }) => {
      state.isSidebarOpen = payload;
    },
    clearSearch: (state) => {
      state.search = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getStates.fulfilled, (state, { payload }) => {
        state.states = payload.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        state.fetchingStatesStatus = status.SUCCESS;
      })

      .addCase(thunks.getCities.fulfilled, (state, { payload }) => {
        state.cities = payload.data;
        state.fetchingCitiesStatus = status.SUCCESS;
      })

      .addCase(thunks.getLocations.fulfilled, (state, { payload }) => {
        state.locations = payload.data;
      })

      .addCase(thunks.getTeachers.fulfilled, (state, { payload }) => {
        state.teachers = payload.data;
      })

      .addCase(thunks.search.pending, (state) => {
        state.fetchingSearchStatus = status.PENDING;
      })
      .addCase(thunks.search.fulfilled, (state, { payload }) => {
        state.search = payload.data;
        state.fetchingSearchStatus = status.SUCCESS;
      })
      .addCase(thunks.search.rejected, (state) => {
        state.fetchingSearchStatus = status.FAIL;
      })

      .addCase(thunks.getProfile.fulfilled, (state, { payload }) => {
        state.profile = payload.data;
      })

      .addCase(thunks.getRecipientsList.fulfilled, (state, { payload }) => {
        const preparedRecipients = payload.data.map((r) => ({
          ...r,
          value: r.userId,
          label: r.fullName,
        }));

        state.recipientsList = preparedRecipients;
      });
  },
});

const general = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { general };
export default slice.reducer;
