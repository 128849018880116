export const eventDefaultValuesModel = ({
  id,
  name,
  status,
  organization,
  city,
  address_first_line,
  address_second_line,
  postcode,
  date_start,
  date_end,
  details,
  timelines,
  dancers_count,
  dancers,
  performances_count,
  date_start_formatted,
  date_end_formatted,
  location_formatted,
}) => {
  const preparedTimelines = timelines.map(
    ({
      id,
      date,
      name,
      userLocalTimeStartFormatted,
      userLocalTimeEndFormatted,
    }) => ({
      id,
      // TODO: refactor to ISO
      date: Date.parse(date),
      name,
      time_start: {
        label: userLocalTimeStartFormatted,
        value: userLocalTimeStartFormatted,
      },
      time_end: {
        label: userLocalTimeEndFormatted,
        value: userLocalTimeEndFormatted,
      },
    })
  );

  return {
    id,
    name,
    dancers: dancers || [],
    status: status || '',
    organization: organization || '',
    city: city || '',
    address_first_line: address_first_line || '',
    address_second_line: address_second_line || '',
    postcode: postcode || '',
    // TODO: refactor to ISO
    dateRange: [Date.parse(date_start), Date.parse(date_end)],
    details: details || '',
    timelines: preparedTimelines,
    formatted: {
      dancers_count,
      performances_count,
      date_start_formatted,
      date_end_formatted,
      location_formatted,
    },
  };
};
