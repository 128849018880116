import PropTypes from 'prop-types';
import { Wrap, DotFlashing } from './styled';

export const RLoader = ({ css }) => {
  return (
    <Wrap css={css}>
      <DotFlashing />
    </Wrap>
  );
};

RLoader.propTypes = {
  css: PropTypes.object,
};
