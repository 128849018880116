import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TextEllipsis } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  position: relative;
`;

const Container = styled(Link)`
  display: inline-block;
  ${({ $width }) => TextEllipsis({ width: `${$width || 150}px` })};
  padding-left: 2px;
`;

export { Wrap, Container };
