import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const DancersWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  strong {
    font-weight: 500;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: 16px;
`;

const LinkStyled = styled(NavLink)`
  width: auto !important;
  color: #0500ff !important;
  font-weight: 600;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 600;
  }
`;

export { LinkStyled, Info, Icon, DancersWrap };
