const isNonFileObject = (value) =>
  typeof value === 'object' && value !== null && !(value instanceof File);

const processValue = (value) =>
  value && typeof value === 'object' && 'value' in value ? value.value : value;

const appendItem = (formData, key, item) => {
  if (isNonFileObject(item)) {
    formData.append(key, JSON.stringify(item));
  } else {
    if (typeof item === 'boolean') {
      formData.append(key, item ? '1' : '0');
      return;
    }

    formData.append(key, item);
  }
};

const appendProcessedValue = (formData, key, value) => {
  if (Array.isArray(value)) {
    value.forEach((item, index) =>
      appendItem(formData, `${key}[${index}]`, item)
    );
  } else {
    appendItem(formData, key, value);
  }
};

const prepareFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    const processedValue = processValue(value);
    appendProcessedValue(formData, key, processedValue);
  });
  return formData;
};

const generateArrayOfYears = (yearsCount = 70) => {
  const max = new Date().getFullYear();
  const min = max - yearsCount;
  const years = [];

  // eslint-disable-next-line no-plusplus
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]); // deprecated for NodeJS only
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

function formatPhoneNumber(phone) {
  return phone?.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
}

const priceValueToLocalString = (value) => {
  let preparedValue = value;

  if (typeof value === 'string') {
    preparedValue = parseFloat(value);
  }

  return preparedValue?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export {
  prepareFormData,
  generateArrayOfYears,
  dataURLtoFile,
  formatPhoneNumber,
  priceValueToLocalString,
};
