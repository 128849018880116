import styled from 'styled-components';

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 12px 12px 0 0;
  flex: 1;

  ${({ noScroll }) => noScroll && 'overflow: hidden;'};
`;
const Wrap = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: ${({ $height }) => `${$height}px`};
  background-color: ${({ theme }) => theme.gray25};
  border-radius: 12px;
  width: 100%;
`;

const TCell = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  width: auto;
  height: 58px;
  border-bottom: 0.5px solid ${({ theme }) => theme.gray75};
  padding: 16px 16px;
  color: ${({ theme }) => theme.gray400};

  ${({ css }) => css};
`;

const THead = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${TCell} {
    flex: 1;
    height: 44px;
    padding: 13px 16px;
    border: none;
    background-color: #edecf2;
    color: #667085;
  }
`;

const TBody = styled.div`
  flex: 1 1 auto;
  height: ${({ height }) => `${height}px` || '510px'};
`;

const TRow = styled.div`
  display: flex;
  width: 100%;

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  ${TCell} {
    flex: 1;
  }

  ${({ isCursive }) => isCursive && 'font-style: italic;'};

  ${({ css }) => css};
`;

const EmptyText = styled.div`
  color: ${({ theme }) => theme.greyChateau};
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
  width: 100%;
  height: 100%;
`;

const PaginationStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.gray25};
  border-radius: 0px 0 12px 12px;
`;

const LoadingStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export {
  ScrollContainer,
  Wrap,
  THead,
  TBody,
  TRow,
  TCell,
  EmptyText,
  PaginationStyled,
  LoadingStyled,
};
