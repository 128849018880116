import { parse } from 'date-fns';

import { appDateFormat } from './const';

const createStringSort = (prop) => (a, b, isAsc) => {
  const aValue = typeof a[prop] === 'string' ? a[prop] : '';
  const bValue = typeof b[prop] === 'string' ? b[prop] : '';

  if (aValue === '' && bValue !== '') return 1;
  if (bValue === '' && aValue !== '') return -1;

  const compareResult = aValue.localeCompare(bValue);

  return isAsc ? compareResult : -compareResult;
};

const createNumbersSort = (prop) => (a, b, isAsc) => {
  const aValue = typeof a[prop] === 'number' ? a[prop] : 0;
  const bValue = typeof b[prop] === 'number' ? b[prop] : 0;

  const compareResult = aValue - bValue;

  return isAsc ? compareResult : -compareResult;
};

const createDateSort = (prop) => (a, b, isAsc) => {
  const dateA = a[prop]
    ? parse(a[prop], appDateFormat.M_FULL_D_Y, new Date())
    : -Infinity;
  const dateB = b[prop]
    ? parse(b[prop], appDateFormat.M_FULL_D_Y, new Date())
    : -Infinity;

  const compareResult = dateA - dateB;

  return isAsc ? compareResult : -compareResult;
};

const createChildrenSort = (a, b, isAsc) => {
  const compareResult = a.child.length - b.child.length;
  return isAsc ? compareResult : -compareResult;
};

const createTeachersSort = (a, b, isAsc) => {
  const aValue = a.teachers.map((t) => t.full_name).join('');
  const bValue = b.teachers.map((t) => t.full_name).join('');

  if (aValue === '' && bValue !== '') return 1;
  if (bValue === '' && aValue !== '') return -1;

  const compareResult = aValue.localeCompare(bValue);

  return isAsc ? compareResult : -compareResult;
};

export {
  createStringSort,
  createNumbersSort,
  createDateSort,
  createChildrenSort,
  createTeachersSort,
};
