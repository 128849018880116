import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { OverflowPopup } from 'components/General/Cells/OverflowPopup';
import { useTruncationAndOverflowPopup } from 'hooks/useCellTruncation';

import { Wrap, Container } from './styled';

LinkCell.propTypes = {
  to: PropTypes.string,
  value: PropTypes.string,
};

function LinkCell({ value, to, width }) {
  const { textRef, showPopup, handleMouseEnter, handleMouseLeave } =
    useTruncationAndOverflowPopup(value);

  return (
    <Wrap
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Container
        $width={width}
        ref={textRef}
        to={to}
      >
        {value}
      </Container>
      <OverflowPopup
        visible={showPopup}
        ref={textRef}
      >
        <Link to={to}>{value}</Link>
      </OverflowPopup>
    </Wrap>
  );
}

export const MemoizedLinkCell = memo(LinkCell);
