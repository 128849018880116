import yup from 'plugins/yup-config';

const prepareSelectField = (options = []) => {
  if (!Array.isArray(options)) return [];

  return options.map((option) => ({
    value: option,
    label: option,
  }));
};

const isFieldWithObjValueFilled = (value) => {
  return !value?.label ? null : value;
};

const validateTimeFormat = () =>
  yup
    .object()
    .transform(isFieldWithObjValueFilled)
    .test('is-valid-time', 'Invalid time format', (value) => !!value)
    .nullable();

const prepareFormForSend = (data) => {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === 'object' && value !== null && 'value' in value) {
      data[key] = value.value;
    }
  });

  return data;
};

export {
  prepareSelectField,
  isFieldWithObjValueFilled,
  prepareFormForSend,
  validateTimeFormat,
};
