import PropTypes from 'prop-types';

import { CROSS_SVG } from 'components/ui/_shared/SVG';
import styled from 'styled-components';

const ChipStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 4px;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray75};
`;

const CloseBtnStyled = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Chip = ({ text, id, onClose }) => (
  <ChipStyled>
    {text}

    {onClose && (
      <CloseBtnStyled onClick={() => onClose(id)}>
        <CROSS_SVG />
      </CloseBtnStyled>
    )}
  </ChipStyled>
);

Chip.propTypes = {
  text: PropTypes.string,
  id: PropTypes.number,
  onClose: PropTypes.func,
};
