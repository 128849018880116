import { useId } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { HelperText, LabelStatic } from 'components/ui/_shared/styled';
import { Label, Wrap, Input } from './styled';

export const CheckboxField = ({
  checked,
  disabled,
  onChange,
  label,
  labelCss,
}) => {
  const id = useId();

  return (
    <Wrap>
      <Input
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <Label
        htmlFor={id}
        style={labelCss}
        $disabled={disabled}
      >
        {label}
      </Label>
    </Wrap>
  );
};

CheckboxField.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  labelCss: PropTypes.object,
};

export const RCheckboxField = ({
  name,
  title,
  label,
  control,
  labelCss,
  disabled,
  hideValidation = false,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <>
      {title && <LabelStatic>{title}</LabelStatic>}

      <CheckboxField
        checked={value}
        disabled={disabled}
        onChange={onChange}
        label={label}
        labelCss={labelCss}
      />
      {!hideValidation && <HelperText>{error && error.message}</HelperText>}
    </>
  );
};

RCheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  control: PropTypes.any,
  labelCss: PropTypes.object,
  disabled: PropTypes.bool,
  hideValidation: PropTypes.bool,
};
