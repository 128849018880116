import PropTypes from 'prop-types';
import loadingImg from 'assets/img/loading.png';
import { Button, ButtonChildren, LoadingStyled, PrependIcon } from './styled';

RButton.propTypes = {
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  prependIcon: PropTypes.any,
  type: PropTypes.string,
  variantColor: PropTypes.string,
  height: PropTypes.number,
  form: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  css: PropTypes.any,
  innerRef: PropTypes.any,
};

export function RButton({
  type = 'button',
  variant,
  variantColor,
  prependIcon,
  fullWidth,
  height = 48,
  disabled = false,
  loading = false,
  onClick = () => {},
  children,
  css,
  form,
  innerRef,
  ...rest
}) {
  return (
    <Button
      type={type}
      variant={variant}
      variantColor={variantColor}
      fullWidth={fullWidth}
      height={height}
      disabled={disabled}
      onClick={disabled || loading ? null : onClick}
      css={css}
      form={form}
      ref={innerRef}
      {...rest}
    >
      <ButtonChildren>
        {loading ? (
          <LoadingStyled
            src={loadingImg}
            alt="loading"
          />
        ) : (
          <>
            {prependIcon && (
              <PrependIcon disabled={disabled}>{prependIcon}</PrependIcon>
            )}

            {children}
          </>
        )}
      </ButtonChildren>
    </Button>
  );
}
