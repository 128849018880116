import { RootFieldIcons, RootFieldInput } from 'components/ui/_shared/styled';
import styled from 'styled-components';

const MultipleSelectModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.gray25};
  border-radius: 16px;

  width: 530px;
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.gray75};

  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
`;

const BodyStyled = styled.div`
  padding: 24px;
`;

const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
`;

const ItemsWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 340px;
  gap: 12px;
  overflow-y: auto;
`;

const OptionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.gray50};
  padding: 12px 16px;

  cursor: pointer;

  label {
    cursor: pointer;
  }
`;

const OptionLabelStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.gray950};
`;

const FieldInput = styled(RootFieldInput)`
  padding: 16px 16px 16px 50px;
`;

const FieldIcons = styled(RootFieldIcons)`
  left: 18px;
`;

export {
  MultipleSelectModalStyled,
  HeaderStyled,
  BodyStyled,
  FooterStyled,
  ItemsWrapStyled,
  OptionStyled,
  OptionLabelStyled,
  FieldInput,
  FieldIcons,
};
