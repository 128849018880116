import PropTypes from 'prop-types';

import { POPUP_BTN_CHEVRON_SVG } from '../_shared/SVG';
import { ChildrenWrapperStyled, PopupButtonStyled } from './styled';

export const PopupButton = ({ onClick, prependIcon, children }) => {
  return (
    <PopupButtonStyled onClick={onClick}>
      <ChildrenWrapperStyled>
        {prependIcon} {children}
      </ChildrenWrapperStyled>

      <POPUP_BTN_CHEVRON_SVG />
    </PopupButtonStyled>
  );
};

PopupButton.propTypes = {
  onClick: PropTypes.func,
  prependIcon: PropTypes.node,
  children: PropTypes.node,
};
