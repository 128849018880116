import PropTypes from 'prop-types';

import { RMask } from 'components/RMask';
import { RPopupStyled } from './styled';

RPopup.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  css: PropTypes.any,
  maskCss: PropTypes.any,
};

function RPopup({ css, color = 'rgba(9, 7, 11, 0.32)', maskCss, children }) {
  return (
    <RMask
      color={color}
      css={maskCss}
    >
      <RPopupStyled css={css}>{children}</RPopupStyled>
    </RMask>
  );
}

export { RPopup };
