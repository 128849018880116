import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { RModalPortal } from 'components/RModalPortal';
import { RPopup } from 'components/RPopup';
import { CROSS_SVG, PLUS_SVG } from 'components/ui/_shared/SVG';
import { RTextField } from 'components/ui/RTextField';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { RTextareaField } from 'components/ui/RTextareaField';
import { Typography } from 'components/App/GlobalStyled';
import { RAutocompleteField } from 'components/ui/RAutocompleteField';
import { RButton } from 'components/ui/RButton';
import { defaultValues, schema } from './config';
import { Chip } from './Chip';
import {
  AddRecipientInputStyled,
  AddRecipientStyled,
  BtnsWrapperStyled,
  CloseBtnStyled,
  ErrorStyled,
  FieldWrapperStyled,
  MessageFormStyled,
} from './styled';

export const MessageModal = ({
  selectedRecipients = [],
  recipientsList = [],
  type,
  onSubmit: onSubmitProp,
  onClose,
  disableAdding,
  prepareRecipientsOnSubmit,
}) => {
  const theme = useTheme();
  const recipientSearchRef = useRef();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, recipients: selectedRecipients, type },
  });

  const options = recipientsList.filter(
    (recipient) =>
      !watch('recipients').some(
        (selectedRecipient) => selectedRecipient.value === recipient.value
      )
  );

  useOutsideClick(recipientSearchRef, () => {
    if (isSearchOpen) {
      setSearchOpen(false);
    }
  });

  const onRecipientRemove = (value) => {
    const recipients = getValues('recipients');

    setValue(
      'recipients',
      recipients.filter((r) => r.value !== value),
      { shouldValidate: true }
    );
  };

  const onAddRecipientClick = () => {
    setSearchOpen(true);
  };

  const handleChoose = () => {
    setSearchOpen(false);

    const selectedRecipient = getValues('recipientSearch');
    if (!selectedRecipient) return;

    setValue('recipients', [...getValues('recipients'), selectedRecipient], {
      shouldValidate: true,
    });
  };

  const onSubmit = async ({ messageText, subject, recipients }) => {
    setLoading(true);

    const preparedData = {
      messageFor: prepareRecipientsOnSubmit
        ? recipients.map((r) => r.value)
        : recipients,
      subject,
      messageText,
    };

    await onSubmitProp(preparedData);

    onClose();
    setLoading(false);
  };

  return (
    <RModalPortal>
      <RPopup color="rgba(244, 243, 249, 0.7)">
        <MessageFormStyled onSubmit={handleSubmit(onSubmit)}>
          <CloseBtnStyled onClick={onClose}>
            <CROSS_SVG
              size={24}
              color={theme.gray90}
            />
          </CloseBtnStyled>

          <FieldWrapperStyled>
            <Typography
              fw="500"
              color={theme.gray600}
            >
              Message for:
            </Typography>

            {watch('recipients').map((r) => (
              <Chip
                key={r.value}
                text={r.label}
                id={r.value}
                onClose={onRecipientRemove}
              />
            ))}

            {errors.recipients && (
              <ErrorStyled>{errors.recipients.message}</ErrorStyled>
            )}

            {!disableAdding && (
              <>
                {isSearchOpen ? (
                  <AddRecipientInputStyled ref={recipientSearchRef}>
                    <RAutocompleteField
                      name="recipientSearch"
                      control={control}
                      options={options}
                      labelStatic
                      handleChoose={handleChoose}
                      clearOnChoose
                    />
                  </AddRecipientInputStyled>
                ) : (
                  <AddRecipientStyled onClick={onAddRecipientClick}>
                    <PLUS_SVG size={12} />
                  </AddRecipientStyled>
                )}
              </>
            )}
          </FieldWrapperStyled>

          <FieldWrapperStyled>
            <Typography
              fw="500"
              color={theme.gray600}
            >
              Type:
            </Typography>

            <Chip text={type} />
          </FieldWrapperStyled>

          {type === 'email' && (
            <FieldWrapperStyled removePadding>
              <Typography
                p="7px 0 0"
                fw="500"
                color={theme.gray600}
              >
                Subject:
              </Typography>

              <RTextField
                height={20}
                name="subject"
                variant="transparent"
                control={control}
              />
            </FieldWrapperStyled>
          )}

          <RTextareaField
            name="messageText"
            fullWidth
            control={control}
            height={100}
            variant="transparent"
            placeholder="Message text"
          />

          <BtnsWrapperStyled>
            <RButton
              type="submit"
              loading={loading}
            >
              Send
            </RButton>
            <RButton
              variant="outlineBordered"
              onClick={loading ? null : onClose}
            >
              Cancel
            </RButton>
          </BtnsWrapperStyled>
        </MessageFormStyled>
      </RPopup>
    </RModalPortal>
  );
};

MessageModal.propTypes = {
  selectedRecipients: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  recipientsList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  disableAdding: PropTypes.bool,
  prepareRecipientsOnSubmit: PropTypes.bool,
};
