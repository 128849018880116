import { MemoizedLinkCell } from 'components/General/Cells/LinkCell';
import { createNumbersSort, createStringSort } from 'utils/sorting';
import { ActionsCell } from './ActionsCell';

const columns = [
  {
    slug: 'full_name',
    title: 'Dancer',
    style: {
      minWidth: '420px',
      color: '#090A14',
    },
    headerStyle: {
      color: '#667085',
    },
    sortFunction: createStringSort('full_name'),
    render: (row) => (
      <MemoizedLinkCell
        to={`/dancers/${row.id}/details`}
        value={row.full_name}
      />
    ),
  },
  {
    slug: 'primary_contact_full_name',
    title: 'Primary Contact',
    style: {
      minWidth: '420px',
    },

    sortFunction: createStringSort('primary_contact_full_name'),
    render: (row) => (
      <MemoizedLinkCell
        to={`/${
          row.primary_contact_role === 'Parent' ? 'parents' : 'dancers'
        }/${row.primary_contact_id}/details`}
        value={row.primary_contact_full_name}
      />
    ),
  },
  {
    slug: 'age',
    title: 'Age',
    style: {
      minWidth: '300px',
    },

    sortFunction: createNumbersSort('age'),
  },
  {
    slug: '',
    title: '',
    style: {
      minWidth: '140px',
    },

    render: (row) => <ActionsCell row={row} />,
  },
];

export { columns };
