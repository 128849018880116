const selectors = {
  classInfo: (state) => state.classDetails.classInfo,
  loadingClassInfoStatus: (state) => state.classDetails.loadingClassInfoStatus,
  classAppointments: (state) => state.classDetails.classAppointments,
  loadingAppointmentsStatus: (state) =>
    state.classDetails.loadingAppointmentsStatus,
  submittedValuesForCalculation: (state) =>
    state.classDetails.submittedValuesForCalculation,
  scheduleCalculation: (state) => state.classDetails.scheduleCalculation,
  schedule: (state) => state.classDetails.schedule,
  pricing: (state) => state.classDetails.pricing,
  getClassScheduleListStatus: (state) =>
    state.classDetails.getClassScheduleListStatus,
  classDeletingStatus: (state) => state.classDetails.classDeletingStatus,
  setActivationStatus: (state) => state.classDetails.setActivationStatus,
  dancers: {
    classDancers: (state) => state.classDetails.dancers.classDancers,
    loadingClassDancersStatus: (state) =>
      state.classDetails.dancers.loadingClassDancersStatus,
    list: (state) => state.classDetails.dancers.list,
    currentPage: (state) => state.classDetails.dancers.currentPage,
  },
};

export { selectors };
