import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getConfigurationSettings = createAsyncThunk(
  'configuration/getConfigurationSettings',
  async () => {
    try {
      const res = await api.get(WEB_API_ROUTES.settings.configuration);
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateConfigurationSettings = createAsyncThunk(
  'configuration/updateConfigurationSettings',
  async (data) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.settings.updateConfiguration,
        data
      );
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteLocation = createAsyncThunk(
  'configuration/deleteLocation',
  async (locationId) => {
    try {
      const res = await api.delete(
        WEB_API_ROUTES.settings.deleteConfiguration.replace(
          '{locationId}',
          locationId
        )
      );
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteRoom = createAsyncThunk(
  'configuration/deleteRoom',
  async (roomId) => {
    try {
      const res = await api.delete(
        WEB_API_ROUTES.settings.deleteRoom.replace('{roomId}', roomId)
      );
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getConfigurationSettings,
  updateConfigurationSettings,
  deleteLocation,
  deleteRoom,
};

export { thunks };
