import styled from 'styled-components';

const Main = styled.div``;

const DeleteAvatar = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;

  svg {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Wrap = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  position: relative;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 50%;
  overflow: hidden;
  z-index: 0; // to remove safari artefacts with border radius

  &:hover {
    ${DeleteAvatar} {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled.div`
  font-size: ${({ size }) => `${size / 2}px`};
  color: ${({ theme }) => theme.spanishGray};
  font-weight: 500;
  text-transform: uppercase;
`;

export { Main, Wrap, Image, Name, DeleteAvatar };
