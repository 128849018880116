export const prepareDancers = (dancers) =>
  dancers.map(({ id, dancer }) => ({
    label: dancer,
    value: id,
  }));

export const prepareParents = (parents) => {
  return parents.map((p) => ({
    label: p.name,
    value: p.id,
  }));
};

export const prepareEvents = (events) => {
  return events.map((e) => ({
    label: e.name,
    value: e.id,
  }));
};
