import { theme } from 'components/App/Theme';
import styled, { css } from 'styled-components';
import { colors } from 'utils/colors';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '280px')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const FieldWrap = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: ${({ focus, activated }) => (focus || activated ? '0' : '50%')};
  left: 12px;
  padding: 0 4px;
  font-size: ${({ focus, activated }) =>
    focus || activated ? '12px' : '14px'};
  line-height: 1;
  color: ${({ error, disabled, focus, theme, activated }) => {
    if (disabled) {
      return activated ? theme.gainsboro : theme.spanishGray;
    }
    if (error) {
      return theme.error;
    }
    if (focus) {
      return theme.cyanBlueAzure;
    }
    if (activated) {
      return theme.davyGrey;
    }
    return theme.spanishGray;
  }};
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.1s linear;

  ${({ focus, activated, theme }) =>
    (focus || activated) &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${theme.white};
        z-index: -1;
      }
    `};
`;

const LabelStatic = styled.label`
  display: block;
  margin-left: 18px;
  line-height: 25px;
  color: ${({ error, disabled, focused, theme }) => {
    if (disabled) {
      return theme.gray500;
    }
    if (error) {
      return theme.error;
    }
    if (focused) {
      return theme.brand500;
    }
    return theme.gray600;
  }};
  transition: 0.1s linear;
  font-weight: 500;
  font-size: 16px;
`;

export const rootFieldInputVariants = {
  underlined: 'underlined',
  underlinedTransparent: 'underlinedTransparent',
  transparent: 'transparent',
};

const RootFieldInput = styled.input`
  display: block;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ activated, theme }) =>
    activated ? theme.gray950 : theme.gray200};
  background-color: ${({ theme }) => theme.gray25};
  border: 0.5px solid
    ${({ error }) => {
      if (error) {
        return theme.error;
      }
      return colors.gray100;
    }};

  border-radius: 12px;
  transition: 0.15s linear;

  &::placeholder {
    color: ${colors.gray200};
  }

  ${({ error, disabled }) =>
    !error &&
    !disabled &&
    css`
      &:hover {
        border-color: ${colors.brand500};
      }

      &:focus {
        border-color: ${colors.brand500};
      }
    `}

  ${({ labelStatic }) =>
    !labelStatic &&
    css`
      &::placeholder {
        opacity: 0;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }
    `}

  ${({ variant, theme, focus }) => {
    switch (variant) {
      case rootFieldInputVariants.underlined:
        return css`
          color: ${theme.gray600};
          border-radius: 4px;
          border: none;
          border-bottom: 2px solid ${focus ? theme.brand500 : 'none'};
          background-color: ${theme.gray50};

          &::placeholder {
            color: ${theme.gray200};
          }
        `;
      case rootFieldInputVariants.underlinedTransparent:
        return css`
          color: ${theme.gray600};
          border-radius: 4px;
          border: none;
          border-bottom: 2px solid ${focus ? theme.brand500 : 'none'};
          background-color: ${focus ? theme.gray50 : 'transparent'};

          &::placeholder {
            color: ${theme.gray200};
          }
        `;
      case rootFieldInputVariants.transparent:
        return css`
          color: ${theme.gray600};
          border: none;
          background-color: 'transparent';

          &::placeholder {
            color: ${theme.gray600};
          }
        `;
      default:
        return '';
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.gray200};
      border-color: ${colors.gray100};
      pointer-events: none;
    `}

    ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
    `}
`;

const HelperText = styled.div`
  flex: 1 1 auto;
  min-height: 12px;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.error};
  margin: 2px 0 2px 10px;
`;

const RootFieldIcons = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const FieldDropdown = styled.div`
  position: absolute;
  ${({ dropdownPosition }) =>
    dropdownPosition === 'top' ? 'bottom: 110%;' : 'top: 100%;'};
  left: 0;
  width: 100%;
  height: auto;
  max-height: 166px;
  padding: 6px 0;
  background-color: ${({ theme }) => theme.gray25};
  box-shadow: 0 4px 6px 1px rgba(74, 80, 87, 0.13);
  border-radius: 8px;
  overflow-y: auto;
  z-index: 10;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.gray100};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px 8px 8px 0px;
  }
`;

const RootFieldDropdownOption = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  /* ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.gray50};
    `} */

  &:hover {
    background-color: ${({ theme }) => theme.gray50};
  }
`;

const DropdownOptionPrependIcon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
`;

const CheckMarkStyled = styled.div`
  margin-left: auto;
`;

export {
  Wrap,
  Label,
  LabelStatic,
  FieldWrap,
  RootFieldInput,
  HelperText,
  RootFieldIcons,
  FieldDropdown,
  RootFieldDropdownOption,
  DropdownOptionPrependIcon,
  CheckMarkStyled,
};
