import styled, { css } from 'styled-components';

import { Label } from '../_shared/styled';

const FieldLabel = styled(Label)`
  top: ${({ focus, activated }) => (focus || activated ? '0' : '20px')};
`;

const FieldInput = styled.textarea`
  display: block;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 16px;

  resize: none;
  max-height: 300px;
  color: ${({ theme }) => theme.maastrichtBlue};
  background-color: ${({ theme }) => theme.white};
  border: 0.5px solid
    ${({ error, activated, theme }) =>
      // eslint-disable-next-line no-nested-ternary
      error ? theme.error : activated ? theme.brand500 : theme.gray100};
  border-radius: 12px;
  transition: 0.15s linear;

  ::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: padding-box;
    border-radius: 12px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::placeholder {
    color: ${({ disabled, theme }) =>
      disabled ? theme.gainsboro : theme.spanishGray};
  }

  ${({ error, theme }) =>
    !error &&
    css`
      &:hover {
        border-color: ${theme.brand500};
      }

      &:focus {
        border-color: ${theme.brand500};
      }
    `}

  ${({ activated, disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.gainsboro};

      color: ${activated ? theme.davyGrey : theme.gainsboro};
      pointer-events: none;
    `}

  ${({ labelStatic }) =>
    !labelStatic &&
    css`
      &::placeholder {
        opacity: 0;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }
    `}

  ${({ variant }) =>
    variant === 'transparent' &&
    css`
      padding: 0;
      background-color: transparent;
      border: none;

      &::placeholder {
        opacity: 1;
      }
    `}
`;

const HelperBlock = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const AmountSymbols = styled.div`
  min-height: 18px;
  font-size: 10px;
  font-weight: 500;
  margin: 2px 0;
`;

export { FieldLabel, FieldInput, HelperBlock, AmountSymbols };
