import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { TableRefContext } from 'components/RTable';
import { Wrap, P } from './styled';

export const OverflowPopup = forwardRef(({ children, visible }, ref) => {
  const { tableRef } = useContext(TableRefContext);
  const popupRef = useRef(null);

  const tableWidth = tableRef.current?.getBoundingClientRect().width;

  const [position, setPosition] = useState('right');
  const [maxWidth, setMaxWidth] = useState(tableWidth);
  const [shouldWrap, setShouldWrap] = useState(false);

  const minDistance = 300;

  useEffect(() => {
    const refsReady =
      ref &&
      ref.current &&
      popupRef &&
      popupRef.current &&
      tableRef &&
      tableRef.current;

    if (refsReady) {
      const cellRect = ref.current.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();

      const popupWidth = ref.current.scrollWidth;
      const popupHeight = popupRef.current.scrollHeight;

      const leftMaxWidth = cellRect.left - tableRect.left - 20;
      const rightMaxWidth = tableRect.right - cellRect.right - 20;

      let newPosition = 'right';
      let newMaxWidth = rightMaxWidth;
      let newShouldWrap = popupWidth >= rightMaxWidth;

      switch (true) {
        case cellRect.top - tableRect.top + popupHeight + 20 > tableRect.height:
          newPosition = 'top';
          newMaxWidth = tableRect.width / 2;
          newShouldWrap = popupWidth >= tableRect.width / 2;
          break;
        // case cellRect.top - popupHeight < tableRect.top:
        //   newPosition = 'bottom';
        //   newMaxWidth = tableRect.width;
        //   newShouldWrap = popupWidth >= tableRect.width;
        //   break;
        case tableRect.right - cellRect.right < minDistance:
          newPosition = 'left';
          newMaxWidth = leftMaxWidth;
          newShouldWrap = popupWidth >= leftMaxWidth;
          break;
        default:
          newPosition = 'right';
          newMaxWidth = rightMaxWidth;
          newShouldWrap = popupWidth >= rightMaxWidth;
      }

      setPosition(newPosition);
      setMaxWidth(newMaxWidth);
      setShouldWrap(newShouldWrap);
    }
  }, [ref, tableWidth]);

  return (
    <Wrap
      ref={popupRef}
      $visible={visible}
      $position={position}
      $tableWidth={maxWidth}
      $shouldWrap={shouldWrap}
      style={{ maxWidth: `${maxWidth}px` }}
    >
      <P
        $shouldWrap={shouldWrap}
        $minWidth={maxWidth - 24}
      >
        {children}
      </P>
    </Wrap>
  );
});

OverflowPopup.displayName = 'OverflowPopup';

OverflowPopup.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};
