import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const logIn = createAsyncThunk('auth/logIn', async (loginData) => {
  try {
    const response = await api.post(WEB_API_ROUTES.auth.logIn, {
      ...loginData,
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const signUp = createAsyncThunk('auth/signUp', async ({ email, password }) => {
  try {
    const response = await api.post(WEB_API_ROUTES.registration.signUp, {
      email,
      password,
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (refresh_token) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.refreshToken, {
        refresh_token,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const logout = createAsyncThunk('auth/logout', async () => {
  try {
    const response = await api.delete(WEB_API_ROUTES.auth.logOut);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const googleLogin = createAsyncThunk('auth/googleLogin', async ({ token }) => {
  try {
    const response = await api.post(WEB_API_ROUTES.auth.googleLogin, {
      token,
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const googleSignUp = createAsyncThunk(
  'auth/googleSignUp',
  async ({ token }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.googleSignUp, {
        token,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const appleLogin = createAsyncThunk('auth/appleLogin', async ({ token }) => {
  try {
    const response = await api.post(WEB_API_ROUTES.auth.appleLogin, {
      token,
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const appleSignUp = createAsyncThunk('auth/appleSignUp', async ({ token }) => {
  try {
    const response = await api.post(WEB_API_ROUTES.auth.appleSignUp, {
      token,
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.forgotPassword, {
        email,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createPassword = createAsyncThunk(
  'auth/createPassword',
  async ({ token, password, passwordConfirmation }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.createPassword, {
        token,
        password,
        passwordConfirmation,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  logIn,
  signUp,
  refreshToken,
  logout,
  googleLogin,
  googleSignUp,
  appleLogin,
  appleSignUp,
  forgotPassword,
  createPassword,
};

export { thunks };
