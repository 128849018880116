const selectors = {
  eventInfo: (state) => state.eventDetails.eventInfo,

  performances: {
    eventPerformances: (state) =>
      state.eventDetails.performances.eventPerformances,
    currentPage: (state) => state.eventDetails.performances.currentPage,
  },
  performancesLoadingStatus: (state) =>
    state.eventDetails.performancesLoadingStatus,

  eventLoadingStatus: (state) => state.eventDetails.eventLoadingStatus,
};

export { selectors };
