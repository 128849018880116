import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { RButtonIcon } from 'components/ui/RButtonIcon';
import { CHEVRON_SVG } from 'components/ui/_shared/SVG';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberBtns = ({ value, onChange, step, min, max }) => {
  const theme = useTheme();

  const increment = () => {
    const newValue = Number(value) + step;
    if (!max || newValue <= max) {
      onChange(newValue);
    }
  };

  const decrement = () => {
    const newValue = Number(value) - step;
    if (!min || newValue >= min) {
      onChange(newValue);
    }
  };

  return (
    <Wrap>
      <RButtonIcon
        size={15}
        onClick={increment}
      >
        <CHEVRON_SVG
          direction="up"
          size="20px"
          thickness="2"
        />
      </RButtonIcon>

      <RButtonIcon
        size={15}
        onClick={decrement}
      >
        <CHEVRON_SVG
          direction="down"
          size="20px"
          thickness="2"
          color={value <= min ? theme.gray200 : '#676770'}
        />
      </RButtonIcon>
    </Wrap>
  );
};

NumberBtns.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};
