import PropTypes from 'prop-types';

import { Card } from 'components/Card';
import { RLoader } from 'components/RLoader';
import { CardHeaderStyled } from 'pages/User/Class/ClassDetails/_shared/styled';

export const CardLoader = ({ title }) => (
  <Card>
    <CardHeaderStyled>{title}</CardHeaderStyled>
    <RLoader css={{ padding: '20px' }} />
  </Card>
);

CardLoader.propTypes = {
  title: PropTypes.string,
};
