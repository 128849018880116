import styled, { css } from 'styled-components';

const Wrap = styled.div(
  ({ theme, css: styles }) => css`
    padding: 16px;
    ${styles};

    .rc-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      list-style: none;

      &-item,
      &-prev,
      &-next {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: 1px solid #d0d5dd;
        cursor: pointer;
        background-color: ${({ theme }) => theme.gray25};
        margin: 0;

        &:hover {
          background-color: ${theme.titanWhite};
        }
      }

      &-item {
        font-size: 14px;
        user-select: none;
        border-radius: 0;

        &-active {
          background-color: #f9fafb;
        }

        :hover {
          background-color: ${({ theme }) => theme.gray75};
        }
      }

      &-prev,
      &-next {
        & > svg {
          width: 20px;
          height: 20px;
        }

        &.rc-pagination-disabled {
          pointer-events: none;
          opacity: 1;
        }
      }
    }
  `
);
export { Wrap };
