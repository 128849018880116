import styled from 'styled-components';

const Wrap = styled.div`
  position: fixed;
  top: ${({ position }) => position.y - 150}px;
  left: ${({ position }) => position.x - 490}px;
  z-index: 999;
`;

const MessageSelectorPopupStyled = styled.div`
  width: 480px;
  padding: 24px;
  background-color: ${({ theme }) => theme.gray25};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;

  ${({ hidden }) => hidden && 'display: none;'}

  z-index: 10;
`;

const BtnsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export { Wrap, MessageSelectorPopupStyled, BtnsWrapperStyled };
