import { format, parse } from 'date-fns';

import store from 'store';
import { appTimeFormat } from './const';

export const convertUserMilitaryTimeToUTC = (localTime) => {
  const userOffset = store.getState().general.profile.timezone.utc_diff;
  const utcDate = new Date(`1970-01-01T${localTime}:00.000Z`);

  utcDate.setUTCHours(utcDate.getUTCHours() - Number(userOffset));

  const hours = utcDate.getUTCHours().toString().padStart(2, '0');
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
  const utcTime = `${hours}:${minutes}`;

  return utcTime;
};

export const formatLessonTime = (time) => {
  const parsedTime = parse(time, appTimeFormat.H_24_SECONDS, new Date());

  return format(parsedTime, appTimeFormat.AMPM_12H);
};

export const formatMilitaryToAMPM = (time) => {
  if (!time) return '';
  const parsedTime = parse(time, appTimeFormat.H_24, new Date());

  return format(parsedTime, appTimeFormat.AMPM_12H_UPPERCASE);
};
