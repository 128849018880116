import { APP_ROUTES } from 'router/appRoutes';
import { DetailsTab } from './DetailsTab';
import { DancersTab } from './DancersTab';

export const pageTabs = [
  {
    title: 'Details',
    path: APP_ROUTES.user.performances.details.info,
    element: <DetailsTab />,
  },
  {
    title: 'Dancers',
    path: APP_ROUTES.user.performances.details.dancers,
    element: <DancersTab />,
  },
];
