import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  locations: [
    {
      name: '',
      locationId: null,
      rooms: [{ roomId: null, name: '' }],
    },
  ],
};

const initialState = {
  configurationValues: defaultValues,
  submitEditStatus: status.IDLE,
  getConfigurationSettingsStatus: status.IDLE,
};

const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    deleteLocation(state, { payload }) {
      state.configurationValues.locations =
        state.configurationValues.locations.filter(
          (location) => location.locationId !== payload
        );
    },
    deleteRoom(state, { payload }) {
      state.configurationValues.locations =
        state.configurationValues.locations.map((location) => ({
          ...location,
          rooms: location.rooms.filter((room) => room.roomId !== payload),
        }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getConfigurationSettings.pending, (state) => {
        state.getConfigurationSettingsStatus = status.PENDING;
      })
      .addCase(
        thunks.getConfigurationSettings.fulfilled,
        (state, { payload }) => {
          if (!payload.data.length) {
            return;
          }

          const preparedData = payload.data.map(
            ({ location: { id, name, rooms } }) => ({
              name,
              locationId: id,
              rooms: rooms.map(({ id, name }) => ({
                roomId: id,
                name,
              })),
            })
          );

          state.getConfigurationSettingsStatus = status.SUCCESS;
          state.configurationValues = { locations: preparedData };
        }
      )
      .addCase(thunks.getConfigurationSettings.rejected, (state) => {
        state.getConfigurationSettingsStatus = status.FAIL;
      })

      .addCase(thunks.updateConfigurationSettings.pending, (state) => {
        state.submitEditStatus = status.PENDING;
      })
      .addCase(thunks.updateConfigurationSettings.fulfilled, (state) => {
        state.submitEditStatus = status.SUCCESS;
      })
      .addCase(thunks.updateConfigurationSettings.rejected, (state) => {
        state.submitEditStatus = status.FAIL;
      });
  },
});

const configuration = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { configuration };
export default slice.reducer;
