import {
  InfoColumnStyled,
  InfoColumnsWrapperStyled,
} from 'pages/User/_shared/entityDetails/styled';
import { PerformanceInfo } from './PerformanceInfo';

export const DetailsTab = () => {
  return (
    <InfoColumnsWrapperStyled>
      <InfoColumnStyled>
        <PerformanceInfo />
      </InfoColumnStyled>

      <InfoColumnStyled />
    </InfoColumnsWrapperStyled>
  );
};
