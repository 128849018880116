import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RButtonIcon } from 'components/ui/RButtonIcon';
import { MESSAGE_SVG, TABLE_CHEVRON_SVG } from 'components/ui/_shared/SVG';
import { useToggle } from 'hooks/useToggle';
import { APP_ROUTES } from 'router/appRoutes';
import { MessageSelectorPopup } from 'pages/User/_shared/MessageSelectorPopup';
import { prepareDancers } from 'pages/User/_shared/MessageSelectorPopup/prepare';
import { dancers } from 'store/dancers';
import { ActionsCellWrapperStyled } from './styled';

export function ActionsCell({ row }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dancersList = useSelector(dancers.selectors.dancersList);
  const preparedDancers = prepareDancers(dancersList);

  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showPopup, toggleShowPopup] = useToggle(false);

  const onMessageClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ y: rect.top, x: rect.left });
    toggleShowPopup();
  };
  const onModalClose = () => toggleShowPopup();

  const onChevronClick = () => {
    navigate(APP_ROUTES.user.dancers.details.index.replace(':id', row.id));
  };

  const onSubmitSms = (data) => {
    dispatch(dancers.thunks.sendSms(data));
  };
  const onSubmitEmail = (data) => {
    dispatch(dancers.thunks.sendEmail(data));
  };

  return (
    <ActionsCellWrapperStyled>
      <RButtonIcon onClick={onMessageClick}>
        <MESSAGE_SVG />
      </RButtonIcon>

      <RButtonIcon onClick={onChevronClick}>
        <TABLE_CHEVRON_SVG />
      </RButtonIcon>

      {showPopup && (
        <MessageSelectorPopup
          recipients={[{ label: row.dancer, value: row.id }]}
          onClose={onModalClose}
          position={popupPosition}
          list={preparedDancers}
          onSubmitSms={onSubmitSms}
          onSubmitEmail={onSubmitEmail}
        />
      )}
    </ActionsCellWrapperStyled>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.object,
};
