import styled, { css } from 'styled-components';

const positionStyles = {
  right: css`
    left: calc(100% + 10px);
    right: auto;
    transform: translateY(-50%);
  `,
  left: css`
    left: auto;
    right: calc(100% + 10px);
    transform: translateY(-50%);
  `,
  top: css`
    top: auto;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%);
  `,
  bottom: css`
    top: calc(100% + 10px);
    bottom: auto;
    transform: translateY(-50%);
  `,
};

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: ${({ $shouldWrap }) => ($shouldWrap ? 'wrap' : 'nowrap')};
  width: fit-content;
  border-radius: 8px;
  height: auto;
  z-index: 1000;
  color: ${({ theme }) => theme.gray500};
  background-color: ${({ theme }) => theme.brand50};
  padding: 8px 12px;

  ${({ $position }) => positionStyles[$position] || null}

  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;

  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  overflow: hidden;

  &::before {
    display: ${({ $position }) =>
      $position === 'top' || $position === 'bottom' ? 'none' : 'block'};
    content: '';
    position: absolute;
    top: 50%;
    left: ${({ $position }) => ($position === 'right' ? '-10px' : 'auto')};
    right: ${({ $position }) => ($position === 'left' ? '-10px' : 'auto')};
    border-width: 5px;
    border-style: solid;
    border-color: ${({ $position, theme }) =>
      $position === 'right'
        ? `transparent ${theme.brand50} transparent transparent`
        : `transparent transparent transparent ${theme.brand50}`};
    transform: translateY(-50%);
  }
`;

const P = styled.p`
  ${({ $shouldWrap, $minWidth }) => css`
    min-width: ${$shouldWrap ? `${$minWidth}px` : 'max-content'};
  `}
`;

export { Wrap, P };
