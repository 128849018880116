export const WEB_API_ROUTES = {
  auth: {
    logIn: '/sign-in/login',
    logOut: '/sign-in/logout',
    refreshToken: '/auth/refresh',
    googleLogin: '/sign-in/with-google',
    googleSignUp: 'sign-up/with-google',
    appleLogin: '/sign-in/with-apple',
    appleSignUp: 'sign-up/with-apple',
    forgotPassword: '/sign-in/forgot-password',
    createPassword: '/sign-in/create-password',
  },
  registration: {
    signUpValidation: '/sign-up/validate',
    profileInfo: '/sign-up/profile-info',
    companyInfo: '/sign-up/company-info',
    signUp: '/sign-up',
    sendVerifyCode: '/sign-up/send-verify-code',
    verifyEmail: '/sign-up/verify-email',
    verifyPhone: '/sign-up/verify-phone',
    completeRegistration: '/sign-up/complete',
  },
  dancers: {
    dancersList: '/dancers',
    sendEmail: '/dancers/notification/email',
    sendSms: '/dancers/notification/phone',
    upcomingAppointments: '/dancers/appointments/upcoming/',
    getRelatives: '/dancers/relatives-list',
    createRelative: '/dancers/store-relative',
    createDancer: '/dancers/store',
    editDancer: '/dancers/update/{dancerId}',
    dancerInfo: '/dancers/{dancerId}',
    removeDancerAvatar: '/dancers/remove-avatar/{dancer_id}',
    evaluationInfo: '/dancers/evaluation/{dancerId}',
    ratings: '/dancers/ratings/{dancerId}',
    performance: '/dancers/performances/{dancerId}',
    dancerNotifications: '/dancers/notification/{dancerId}',
    dancerClasses: '/dancers/classes/{dancerId}',
    availableClasses: '/dancers/classes/list/{dancerId}',
    dancerRoutines: '/dancers/routines/{dancerId}',
    routinesList: '/dancers/routines/list/{dancerId}',
  },
  parents: {
    parentsList: '/parents',
    sendEmail: '/parents/notification/email',
    sendSms: '/parents/notification/phone',
    getRelatives: '/parents/relatives-list',
    createRelative: '/parents/store-relative',
    createParent: '/parents/store',
    editParent: '/parents/update/{parentId}',
    parentInfo: '/parents/{parentId}',
    dancersList: '/parents/{parentId}/dancers-list',
    parentInfoRelatives: '/parents/{parentId}/dancers-list',
    parentNotifications: '/parents/notification/{parentId}',
    parentClasses: 'parents/classes/{parentId}',
  },
  classes: {
    list: '/classes',
    recipients: '/classes/notification/get-recipients-list/{classId}',
    sendEmail: '/classes/notification/email/{classId}',
    sendSms: '/classes/notification/phone/{classId}',
    createClass: '/classes/store',
    deleteClass: '/classes/delete/{classId}',
    editClass: '/classes/update/{classId}',
    classInfo: '/classes/{classId}',
    classAppointments: '/classes/{classId}/upcoming-appointments',
    styles: '/classes/styles',
    dancersList: '/classes/dancers/list/{classId}',
    classDancers: '/classes/dancers/{classId}',
    toggleActive: '/classes/set-activation/{classId}',
    schedule: {
      check: '/classes/schedule/check',
      save: '/classes/schedule/{classId}',
      list: '/classes/schedule/{classId}',
      update: '/classes/schedule/update/{classRoomId}',
    },
    pricing: {
      get: '/classes/pricing-options/{classId}',
      create: '/classes/pricing-options/{classId}',
      update: '/classes/pricing-options/update/{pricingId}',
    },
  },
  routines: {
    list: '/routines',
    styles: '/routines/styles',
    levels: '/routines/levels',
    types: '/routines/types',
    statuses: '/routines/statuses',
    create: '/routines/store',
    routineInfo: '/routines/{routineId}',
    editRoutine: '/routines/update/{routineId}',
    deleteRoutine: '/routines/delete/{routineId}',
    uploadAudio: '/routines/audio/upload/{routineId}',
    removeAudio: '/routines/audio/remove/{routineId}',
    dancersList: '/routines/dancers/list/{routineId}',
    routineDancers: '/routines/dancers/{routineId}',
    routinePerformances: '/routines/performances/list/{routineId}',
  },
  events: {
    list: '/events',
    create: '/events/store',
    edit: '/events/update/{eventId}',
    recipientsList: '/events/notification/get-recipients-list',
    sendEmail: '/events/notification/email',
    sendSms: '/events/notification/phone',
    eventInfo: '/events/{eventId}',
    eventPerformances: '/events/performances/list/{eventId}',
  },
  performances: {
    delete: '/performances/delete/{performanceId}',
    routineDancers: '/performances/dancers/list/{routineId}/{performanceId}',
    createRoutinePerformance: '/routines/performances/{routineId}',
    createEventPerformance: '/events/performances/{eventId}',
    performanceInfo: '/performances/{performanceId}',
    performanceDancers: '/performances/dancers/{performanceId}',
    dancersList: '/performances/dancers/list/{routineId}/{performanceId}',
    updatePerformance: '/performances/update/{performanceId}',
  },
  scheduler: {
    teachers: '/calendar/teachers',
    students: '/calendar/students',
    locations: '/calendar/locations',
    parents: '/calendar/parents',
    classes: '/calendar/classes',
    appointments: '/calendar/appointments',
    storeAppointment: '/calendar/appointments/store',
    updateAppointment: '/calendar/appointments/update',
    deleteAppointment: '/calendar/appointments/delete',
    removeAppointmentMedia: '/calendar/appointments/remove-appointment-medias',
    rejectRequest: '/calendar/appointments/request/reject',
    approveRequest: '/calendar/appointments/request/approve',
    sendEmail: '/calendar/notification/email',
    sendSms: '/calendar/notification/phone',
    cancelAppointment:
      '/calendar/appointments/cancel-and-notify/{appointmentId}',
    updateRating: '/calendar/appointments/ratings/update',
    updateAttendance: '/calendar/appointments/ratings/set-dancer-attendance',
    storeUnavailable: '/calendar/appointments/store-unavailable',
    updateUnavailable:
      '/calendar/appointments/update-unavailable/{appointmentId}',
    users: '/users-list',
    storeInternal: '/calendar/appointments/store-internal',
    updateInternal: '/calendar/appointments/update-internal/{appointmentId}',
  },
  general: {
    search: '/search',
    getStates: '/location/states',
    getCities: '/location/cities',
    locations: 'data/rooms',
    teachers: 'data/teachers',
    profile: '/user-profile',
    recipientsList: '/notification/get-recipients-list',
    sendEmail: '/notification/email',
    sendSms: '/notification/phone',
  },
  settings: {
    configuration: '/settings',
    storeConfiguration: '/settings/store',
    updateConfiguration: '/settings/update',
    deleteConfiguration: '/settings/delete/{locationId}',
    deleteRoom: '/settings/delete/room/{roomId}',
  },
};
