const selectors = {
  list: (state) => state.classes.list,
  loadingListStatus: (state) => state.classes.loadingListStatus,

  preparedEntities: (state) => state.classes.preparedEntities,
  filters: (state) => state.classes.filters,
  pagination: (state) => state.classes.pagination,
};

export { selectors };
