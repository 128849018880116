import { useRef, useState, useEffect } from 'react';

export const useTruncationAndOverflowPopup = (value) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    if (isTruncated) {
      setShowPopup(true);
    }
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;

      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();
  }, [value]);

  return {
    textRef,
    isTruncated,
    showPopup,
    handleMouseEnter,
    handleMouseLeave,
  };
};
