import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { useToggle } from 'hooks/useToggle';
import { SORTING_ARROW_SVG } from 'components/ui/_shared/SVG';
import { SortingArrowStyled } from './styled';

export const SortingArrow = ({
  column,
  isSelected,
  sortFunction,
  setSorting,
}) => {
  const theme = useTheme();
  const [isAsc, toggleIsAsc] = useToggle(true);

  const onArrowClick = () => {
    toggleIsAsc();

    setSorting(() => ({
      column,
      sortFunction,
      isAsc,
    }));
  };

  return (
    <SortingArrowStyled onClick={onArrowClick}>
      <SORTING_ARROW_SVG
        isUp={isAsc}
        color={isSelected ? theme.brand400 : '#667085'}
      />
    </SortingArrowStyled>
  );
};

SortingArrow.propTypes = {
  column: PropTypes.string,
  isSelected: PropTypes.bool,
  sortFunction: PropTypes.func,
  setSorting: PropTypes.func,
};
