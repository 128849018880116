import styled from 'styled-components';

const ActionsCellWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  width: 100%;
`;

export { ActionsCellWrapperStyled };
